body {
  background-color: #000000;
}

.bm-embed {
  min-width: 90vw;
}

.App {
  text-align: center;
  vertical-align: middle;
}

.chart {
  /* margin: 1vh auto 1vh auto; */
  min-height: 70vh;
  max-height: 90vh;
}

footer {
  text-align: center;
  width: 100%;
  color: #e2e2e2;
  font-size: 0.8rem;
}

h1 {
  color: #fff;
  text-align: center;
}


.tooltip {
	position: absolute;
	display: none;
  padding: 0.7em;
	box-sizing: border-box;
	font-size: 10px;
	color: #eeeeee;
	background-color: rgba(0, 0, 0, 0.9);
	text-align: left;
	z-index: 1000;
	pointer-events: none;
	border: 1px solid rgba(255,255,255,  .2);
	border-radius: 4px;
}

.tooltip h3 {
  color: #abab3d;
  font-size: 1.0rem;
  margin: 0 0 0.15em 0;
  padding: 0;
}

.tooltip-prices {
  margin-bottom: 0.5em;
}

.topbar {
  display: flex;
  justify-content: space-between;
  width: 95vw;
  margin: 1em auto;
}

.topbar-group-durations {
  display: flex;
  color: #fff;
}

.topbar-group-durations .duration {
  display: inline-flex;
  margin-left: 0.5em;
  margin-top: 0.4em;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 0.6em 1.2em;
  font-size: 12px;
  cursor: pointer;
  font-weight: 500;
}

.duration:not(.active):hover {
  border-color: rgba(255,255,255, 0.4);
}

.duration.active {
  border-color: #fff;
}


.topbar-group-dtes {
  display: flex;
  color: #fff;
}

.topbar-group-dtes .dte {
  display: inline-flex;
  margin-right: 0.5em;
  margin-top: 0.4em;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 0.6em 1.2em;
  font-size: 12px;
  cursor: pointer;
  font-weight: 500;
}

.dte:not(.active):hover {
  border-color: rgba(255,255,255, 0.4);
}

.dte.active {
  border-color: #fff;
}



.toggles {
  text-align: center;
  color: #fff;
  margin: 1.5em 1.5em;
}

.toggles .toggle {
  display: inline-flex;
  margin-right: 0.5em;
  margin-top: 0.4em;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 0.5em 0.8em;
  font-size: 12px;
  cursor: pointer;
  font-weight: 500;
}


.toggle:not(.active):hover {
  border-color: rgba(255,255,255, 0.4);
}

.toggle.active {
  border-color: #fff;
}

.toggle .indicator {
  font-weight: 700;
  margin-right: 0.4em;
}

.chart-logo {
  position: absolute;
  z-index: 1000;
  width: 130px;
  right: 60px;
  top: 20px;
}

.data-strike-price {
  font-weight: 700;
  backgroundColor: green;
}